import { observable, action } from "mobx"

class Todo {
  id = Math.random()

  @observable title = "title";


  @observable count = 1;

  @observable finished = false;

  @action addOne() {
    this.count = this.count + 1
  }

}

export default Todo;
import React from 'react';
import { Result, Button } from 'antd';

class GetLost extends React.Component {
  constructor(props, context) {
    super()
    this.state = {
    }
  }

  render() {
    return (
      <Result
        status="404"
        title="404"
        subTitle="对不起，您访问的页面不存在。"
        extra={[
          <Button key='0' type="primary" onClick={() => {this.props.history.goBack()}}>返回</Button>,
          <Button key='1' onClick={() => {this.props.history.push('/')}}>首页</Button>,
        ]}
      />
    )
  }
}

export default GetLost;
import AsyncComponent from './asyncComponent'
const menuRouter = [
  {
    name: '统计',
    path: '/count/health',
    root: '/count',
    component: props => AsyncComponent(props, () => import('../count/health/index')),
    select: false,
    icon: require('../images/1-2.png'),
    selectIcon: require('../images/1-1.png'),
    children: [
      {
        title: '健康统计',
      },
      {
        name: '健康统计',
        path: `${'/count/health'}`,
        root: '/count/health',
        select: false,
        permissions: 'Pages.Report.HealthRepor.Find',
        component: props => AsyncComponent(props, () => import('../count/health/index')),
      },
      {
        name: '数据统计',
        path: `${'/count/miss'}`,
        root: '/count/miss',
        select: false,
        permissions: 'Pages.Report.DataReport.Find',
        component: props => AsyncComponent(props, () => import('../count/missCard/index')),
      },
      // {
      // 	name: '消息推送',
      //   path: `${'/count/msgSend'}`,
      //   root: '/count/msgSend',
      //   select: false,
      // 	component: props => AsyncComponent(props, () => import('../count/msgSend/index')),
      // },
    ]
  },
  {
    name: '奶站',
    path: '/region/manage',
    root: '/region',
    component: props => AsyncComponent(props, () => import('../region/manage/region/region')),
    select: false,
    icon: require('../images/2-2.png'),
    selectIcon: require('../images/2-1.png'),
    children: [
      {
        title: '奶站管理',
      },
      // {
      //   name: '奶站管理',
      //   path: `${'/region/manage'}`,
      //   root: '/region/manage',
      //   select: false,
      //   permissions: 'Pages.Dairys.Managements.Find',
      //   component: props => AsyncComponent(props, () => import('../region/manage/region/region')),
      // },

      {
        name: '奶站管理',
        path: `${'/region/manage'}`,
        root: '/region/manage',
        select: false,
        permissions: 'Pages.Dairys.Managements.Find',
        component: props => AsyncComponent(props, () => import('../region/manage/index')),
      },
    ]
  },
  {
    name: '员工',
    path: `${'/staff/general/:id'}`,
    root: '/staff',
    component: props => AsyncComponent(props, () => import('../staff/general/index')),
    select: false,
    icon: require('../images/3-2.png'),
    selectIcon: require('../images/3-1.png'),
    children: [
      {
        title: '员工管理',
      },
      {
        name: '普通员工',
        path: `${'/staff/general/:id'}`,
        root: '/staff/general',
        select: false,
        permissions: 'Pages.Employees.Managements.Find',
        component: props => AsyncComponent(props, () => import('../staff/general/index')),
      },
      {
        name: '防疫信息',
        path: `${'/staff/epidemic/:id'}`,
        root: '/staff/epidemic',
        select: false,
        permissions: 'Pages.Employees.Managements.NAT.Find',
        component: props => AsyncComponent(props, () => import('../staff/epidemic/index')),
      },
      {
        name: '管理人员',
        path: `${'/staff/manager'}`,
        root: '/staff/manager',
        select: false,
        permissions: 'Pages.Employees.Users.Find',
        component: props => AsyncComponent(props, () => import('../staff/manager/index')),
      },
      // {
      //   name: "组织架构",
      //   path: `${'/staff/organization'}`,
      //   root: "/staff/organization",
      //   select: false,
      //   permissions: "Pages.Employees.Organization.Find",
      //   component: props => AsyncComponent(props, () => import('../staff/organization/index'))
      // }
    ]
  },
  {
    name: '财务',
    path: '/finance/set',
    root: '/finance',
    component: props => AsyncComponent(props, () => import('../finance/set/index')),
    select: false,
    icon: require('../images/4-2.png'),
    selectIcon: require('../images/4-1.png'),
    children: [
      {
        title: '财务中心',
      },
      {
        name: '奖金设置',
        path: `${'/finance/set'}`,
        root: '/finance/set',
        select: false,
        permissions: 'Pages.Finances.BonusSets.Find',
        component: props => AsyncComponent(props, () => import('../finance/set/index')),
      },
      {
        name: '奖金明细',
        path: `${'/finance/detail'}`,
        root: '/finance/detail',
        select: false,
        permissions: 'Pages.Finances.BonusDetails.Find',
        component: props => AsyncComponent(props, () => import('../finance/detail/index')),
      },
      {
        name: '打卡奖励',
        path: `${'/finance/send/:id'}`,
        root: '/finance/send',
        select: false,
        permissions: ["Pages.Finances.GrantDetails.GrantReports.Find", "Pages.Finances.GrantDetails.GrantProjects.Find"],
        component: props => AsyncComponent(props, () => import('../finance/send')),
      },
      {
        name: '自定义奖励',
        path: `${'/finance/customRewards'}`,
        root: '/finance/customRewards',
        permissions: "Pages.Finances.CustomRewards.Find",
        select: false,
        component: props => AsyncComponent(props, () => import('../finance/customRewards')),
      },
    ]
  },
  {
    name: '考试',
    path: `${'/exam/manage'}`,
    root: '/exam',
    component: props => AsyncComponent(props, () => import('../exam/manage/index')),
    select: false,
    icon: require('../images/5-2.png'),
    selectIcon: require('../images/5-1.png'),
    children: [
      {
        title: '考试中心',
      },
      {
        name: '考试管理',
        path: `${'/exam/manage'}`,
        root: '/exam/manage',
        select: false,
        permissions: 'Pages.Exams.Managements.Find',
        component: props => AsyncComponent(props, () => import('../exam/manage/index')),
      },
      {
        name: '试题库',
        path: `${'/exam/question/:id'}`,
        root: '/exam/question',
        select: false,
        permissions: ['Pages.Exams.Question.Banks.Find', 'Pages.Exams.Question.Types.Find'],
        component: props => AsyncComponent(props, () => import('../exam/question/index')),
      },
      {
        name: '试题导入',
        path: `${'/exam/lead'}`,
        root: '/exam/lead',
        select: false,
        permissions: ['Pages.Exams.QuestionImports.Find'],
        component: props => AsyncComponent(props, () => import('../exam/lead/index')),
      },
      {
        name: '视频库',
        path: `${'/exam/video'}`,
        root: '/exam/video',
        select: false,
        permissions: 'Pages.Exams.VideoBanks.Find',
        component: props => AsyncComponent(props, () => import('../exam/video/index')),
      },
    ]
  },
  {
    name: '资料',
    path: `${'/management/receive'}`,
    root: '/management',
    component: props => AsyncComponent(props, () => import('../management/receive/index')),
    select: false,
    icon: require('../images/9-2.png'),
    selectIcon: require('../images/9-1.png'),
    children: [
      {
        title: '资料中心',
      },
      {
        name: '资料管理',
        path: `${'/management/receive'}`,
        root: '/management/receive',
        select: false,
        permissions: 'Pages.Means.Managements.Find',
        component: props => AsyncComponent(props, () => import('../management/receive')),
      },
    ]
  },
  {
    name: '推广',
    path: `${'/promotion/contentReview'}`,
    root: '/promotion',
    component: props => AsyncComponent(props, () => import('../promotion/contentReview/index')),
    select: false,
    icon: require('../images/10-2.png'),
    selectIcon: require('../images/10-1.png'),
    children: [
      {
        title: '推广管理',
      },
      {
        name: '推广内容',
        path: `${'/promotion/contentPromotion'}`,
        root: '/promotion/contentPromotion',
        select: false,
        permissions: 'Pages.Popularize.PopularizeContent',
        component: props => AsyncComponent(props, () => import('../promotion/contentPromotion')),
      },
      {
        name: '内容审核',
        path: `${'/promotion/contentReview'}`,
        root: '/promotion/contentReview',
        select: false,
        permissions: 'Pages.Popularize.Managements',
        component: props => AsyncComponent(props, () => import('../promotion/contentReview')),
      },
      {
        name: '数据统计',
        path: `${'/promotion/dataStatistics'}`,
        root: '/promotion/dataStatistics',
        select: false,
        permissions: 'Pages.Popularize.Data',
        component: props => AsyncComponent(props, () => import('../promotion/dataStatistics')),
      },
    ]
  },
  {
    name: '客户',
    path: `${'/customer/manage'}`,
    root: '/customer',
    component: props => AsyncComponent(props, () => import('../customer/manage/index')),
    select: false,
    icon: require('../images/8-2.png'),
    selectIcon: require('../images/8-1.png'),
    children: [
      {
        title: '客户管理',
      },
      {
        name: '客户管理',
        path: `${'/customer/manage'}`,
        root: '/customer/manage',
        select: false,
        permissions: 'Pages.CustomerPage.CustomerPageManagements.Find',
        component: props => AsyncComponent(props, () => import('../customer/manage/index')),
      },
      {
        name: '批量导入',
        path: `${'/customer/lead'}`,
        root: '/customer/lead',
        select: false,
        permissions: 'Pages.CustomerPage.BulkImport.Find',
        component: props => AsyncComponent(props, () => import('../customer/lead/index')),
      },
      // {
      //   name: '标签管理',
      //   path: `${'/customer/lead'}`,
      //   root: '/customer/lead',
      //   select: false,
      //   permissions: 'Pages.Exams.VideoBanks.Find',
      //   component: props => AsyncComponent(props, () => import('../customer/lead/index')),
      // },
    ]
  },
  {
    name: '订单',
    path: '/order/query',
    root: '/order',
    component: props => AsyncComponent(props, () => import('../order/query/index')),
    select: false,
    icon: require('../images/7-2.png'),
    selectIcon: require('../images/7-1.png'),
    children: [
      {
        title: '订单中心',
      },
      {
        name: '订单查询',
        path: `${'/order/query/:phoneNumber'}`,
        root: '/order/query',
        select: false,
        permissions: 'Pages.Orders.Search.Find',
        component: props => AsyncComponent(props, () => import('../order/query/index')),
      },
      {
        name: '订单对接',
        path: `${'/order/docking/:id'}`,
        root: '/order/docking',
        select: false,
        permissions: ["Pages.Orders.Joint.Dairy.Find", "Pages.Orders.Joint.Sanyuan.Find"],
        component: props => AsyncComponent(props, () => import('../order/docking/index')),
      },
      {
        name: '配送管理',
        path: `${'/order/distribution'}`,
        root: '/order/distribution',
        select: false,
        permissions: 'Pages.Orders.Express.Find',
        component: props => AsyncComponent(props, () => import('../order/distribution/index')),
      },
      {
        name: '小区管理',
        path: `${'/order/community/:id'}`,
        root: '/order/community',
        select: false,
        permissions: ["Pages.Orders.Community.Find", "Pages.Orders.CommunityFeedback.Find"],
        component: props => AsyncComponent(props, () => import('../order/community/index')),
      },
    ]
  },
  {
    name: '设置',
    path: '/set/msgTake',
    root: '/set',
    component: props => AsyncComponent(props, () => import('../set/msgTake/index')),
    select: false,
    icon: require('../images/6-2.png'),
    selectIcon: require('../images/6-1.png'),
    children: [
      {
        title: '设置中心',
      },
      {
        name: "组织管理",
        path: `${'/set/department'}`,
        root: '/set/department',
        select: false,
        permissions: 'Pages.Settings.Organizations.Find',
        component: props => AsyncComponent(props, () => import('../set/department/index')),
      },
      {
        name: "职位管理",
        path: `${'/set/duty'}`,
        root: '/set/duty',
        select: false,
        permissions: 'Pages.Settings.Titles.Find',
        component: props => AsyncComponent(props, () => import('../set/duty/index')),
      },
      {
        name: "角色管理",
        path: `${'/set/position'}`,
        root: '/set/position',
        select: false,
        permissions: 'Pages.Settings.Permissions.Find',
        component: props => AsyncComponent(props, () => import('../set/position/index')),
      },
      {
        name: '数据权限',
        path: `${'/set/dataRights'}`,
        root: '/set/dataRights',
        select: false,
        permissions: 'Pages.Settings.Datas.Find',
        component: props => AsyncComponent(props, () => import('../set/dataRights/index')),
      },
      {
        name: '消息管理',
        path: `${'/set/msgTake/:id'}`,
        root: '/set/msgTake',
        select: false,
        permissions: ['Pages.Settings.MessageManagement.SubscribeMessages.Find', 'Pages.Settings.MessageManagement.MessageNotice.Find'],
        component: props => AsyncComponent(props, () => import('../set/msgTake/index')),
      },
      // {
      // 	name: '消息通知',
      //   path: `${'/set/notice'}`,
      //   root: '/set/notice',
      //   select: false,
      // 	component: props => AsyncComponent(props, () => import('../set/notice/index')),
      // },
      {
        name: '通用设置',
        path: `${'/set/general'}`,
        root: '/set/general',
        select: false,
        permissions: 'Pages.Settings.Generic.Find',
        component: props => AsyncComponent(props, () => import('../set/general/index')),
      },
    ]
  }
]


export default menuRouter;

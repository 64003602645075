import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { Notify } from 'zent';
import { ifPermissions } from "../../utils/utils"
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginOrRegister } from '../../models/login/login';
import menuRouter from "../router/menuRouter"
import { Staff } from "../../models/staff/staff"
import './index.less';

const staff = new Staff()

let loginOrRegister = new LoginOrRegister()

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount = () => {
    document.title = '登录'
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return
    }
  }

  // 登录
  async handleSubmit(values) {
    // let phone = phoneCodeValid(values.userNameOrEmailAddress)
    // let code = myTrim(values.password, 'g')
    // if (phone && code) {
    let params = {
      userNameOrEmailAddress: values.userNameOrEmailAddress.trim(),
      password: values.password,
    }
    this.setState({
      loading: true
    })
    let res = await loginOrRegister.authenticate(params)
    if (res.success) {
      let Jurisdiction = {
        dateStart: new Date().getTime(),
        accessToken: res.result.accessToken,
        userName: params.userNameOrEmailAddress,
        title: res.result.title,
      }
      // 如果是业务员，获取业务员负责的大区
      if (res.result.title === 16) {
        sessionStorage.setItem('Jurisdiction', JSON.stringify(Jurisdiction))  //先储存Jurisdiction 否则后续无法获取用户信息
        let userInfo = await staff.getManagerInfo({ id: res.result.userId })
        if (userInfo.success) {
          Jurisdiction.dairyAreaId = userInfo.result.dairyAreaId
          Jurisdiction.dairyAreaName = userInfo.result.dairyAreaName
          Jurisdiction.areaCode = userInfo.result.areaCode
          Jurisdiction.areaName = userInfo.result.areaName
          Jurisdiction.managingDairys = userInfo.result.managingDairys
        }
      }
      sessionStorage.setItem('Jurisdiction', JSON.stringify(Jurisdiction))
      let permissions = await loginOrRegister.getPermisstions({ id: res.result.userId })  //获取角色的权限
      if (permissions.success) {
        Notify.success('登录成功')
        this.handlePermissionsToSession(permissions.result)
      }
    }
    this.setState({
      loading: false
    })
    // }
  }

  // 处理权限数据并且储存到sessionStorage
  handlePermissionsToSession = (permissions) => {
    let arr = [];
    for (let item of permissions) {
      arr.push(item.name)
    }
    sessionStorage.setItem('permissions', arr.join("~"))
    this.handleRouterToUrl()
  }

  // 根据权限判断默认跳转路径
  handleRouterToUrl = () => {
    for (let menu of menuRouter) {
      if (menu.children) {
        for (let item of menu.children) {
          if (ifPermissions(item.permissions)) {
            let path = item.path.indexOf('/:id') > 0 ? item.path.replace(/:id/g, "1") : item.path
            this.props.history.replace(path)
            return false;
          }
        }
      }
    }
  }

  // 注册
  handleToRegister = () => {
    this.props.history.replace('/register')
  }

  // 忘记密码
  handleToForgetPass = () => {
    this.props.history.replace('/retrieve')
  }

  render() {
    return (
      <div className='login'>
        <div className='loginTop'>
          <div className='loginHeader'>
            <img alt="logo" className='loginLogo' src={require('../images/logo.png')} />
            {/* <span className='loginTitle'>大有</span> */}
          </div>
          <div className='loginDesc'>得用信息管理系统</div>
        </div>
        <div className='loginBox'>
          <div className='tabsTab'></div>  {/* 账户密码登录 */}
          <Form
            className="login-form"
            name="register"
            size="middle"
            onFinish={this.handleSubmit.bind(this)}
            scrollToFirstError
          >
            <Form.Item
              name="userNameOrEmailAddress"
              rules={[{ required: true, message: '请输入账号' }]}
            >
              <Input maxLength={20} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入账号" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password maxLength={20} prefix={<LockOutlined className="site-form-item-icon" />} placeholder="请输入密码" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={this.state.loading}>
                登录
              </Button>
            </Form.Item>
            {/* <div className='register'>
              <span className='forgetPass' onClick={this.handleToForgetPass} >忘记密码</span>
              没有账号？<span onClick={this.handleToRegister} >立即注册</span>
            </div> */}
          </Form>
        </div>
      </div>
    )
  }
}

export default Login

import { observable, action} from 'mobx';

class Finance {

  @observable.ref deleteBox = false;

  @observable.ref notThroughBox = false;

  @action resetDataLine(data) {
    this.dataLine = data || {}
  }

}

export default Finance
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ifPermissions } from "../../utils/utils"

@inject('router')
@observer
@withRouter
class RouterMap extends Component {
  constructor(props, context) {
    super()
    this.state = {
      routerBox: []
    }

    this.userName = JSON.parse(sessionStorage.getItem('Jurisdiction')).userName
  }

  componentDidMount = () => {
    const permissions = sessionStorage.getItem('permissions')
    if (!permissions) {
      this.props.history.replace('/404')
    }
    let routerArr = this.props.router.routerArr
    this.parseValue(routerArr)
  }

  parseValue = (item) => {
    let ary = this.state.routerBox
    item.forEach(element => {
      const temp = { ...element }
      if (temp.children && temp.children.length > 0) {
        this.parseValue(temp.children)
      } else {
        ary.push(temp)
      }
    })
    this.setState({
      routerBox: ary
    })
  }

  // 渲染一级/二级路由
  renderRouter = () => {
    const { routerBox } = this.state;
    let routerArr = routerBox.map((item, index) => {
      if (item.root === '/set/general') { //只有admin账号有通用设置的权限
        if (this.userName === 'admin') {
          return item.path ? <Route key={index} exact path={item.path} component={item.component} /> : null
        }
      } else {
        if (item.root === '/set/general') { //只有admin账号有通用设置的权限
          if (this.userName === 'admin') {
            return item.path ? <Route key={index} exact path={item.path} component={item.component} /> : null
          }
        } else {
          return item.path ? <Route key={index} exact path={item.path} component={item.component} /> : null
        }
      }
      return ""
    })
    return routerArr
  }

  // 渲染详情页的路由
  renderDetailRouter = () => {
    let routerArr = this.props.router.routerDetail.map((item, index) => {
      let rendenRouter = null
      if (Array.isArray(item.permissions)) {  //权限可能会有多个 如果是数组的话循环判断，如果不是则直接判断
        if (item.permissions.some(per => ifPermissions(per))) {
          rendenRouter = <Route key={index} exact path={item.path} component={item.component} />
        }
      } else {
        if (ifPermissions(item.permissions)) {
          rendenRouter = <Route key={index} exact path={item.path} component={item.component} />
        }
      }
      return rendenRouter
    })
    return routerArr
  }

  render() {
    return (
      <div>
        {this.renderRouter()}
        {this.renderDetailRouter()}
      </div>
    )
  }
}

export default RouterMap

import host from './serverConfig';

const config = {

  // 下面的地址配合云端 Server 工作  pro
  host: host,

  //获取验证码
  // getCheckCode: `${ host }/api/services/app/SMS/GetCheckCode`,

  //获取验证码测试
  getCheckCode: `${host}/api/services/app/SMS/GetTestCode`,

  // 上传图片
  uploadImg: `${host}/api/services/app/Attach/UploadImg`,

  // 登录
  authenticate: `${host}/api/TokenAuth/Authenticate`,

  // 删除用户
  employeeDelEmployee: `${host}/api/services/app/Employee/DelEmployee`,

  // 会员注册
  registerByPhoneNumber: `${host}/api/services/app/Account/RegisterByPhoneNumber`,

  // 修改密码
  changePassword: `${host}/api/services/app/User/ChangePassword`,

  // 忘记密码
  changePasswordByCode: `${host}/api/services/app/Account/ChangePasswordByCode`,

  // 健康统计
  employeePaging: `${host}/api/services/app/Employee/Paging`,

  // 添加或者修改的公共方法
  createOrUpdate: `${host}/api/services/app/Dairy/CreateOrUpdate`,

  // 获取奶站第一级站点
  getFirstAll: `${host}/api/services/app/Dairy/GetFirstAll`,

  // 获取奶站第二级站点
  pagingGetSecond: `${host}/api/services/app/Dairy/PagingGetSecond`,

  // 删除奶站
  dairyDelete: `${host}/api/services/app/Dairy/Delete`,

  // 获取给人信息
  getEmployee: `${host}/api/services/app/Employee/GetEmployee`,

  // 缺卡记录
  noClockEmployeePaging: `${host}/api/services/app/Employee/NoClockEmployeePaging`,

  // 缺卡记录导出
  downNoClockEmployeeReport: `${host}/api/services/app/Employee/DownNoClockEmployeeReport`,

  // 导出报表
  downClockInReport: `${host}/api/services/app/Employee/DownClockInReport`,

  // 活动分页
  rewardPaging: `${host}/api/services/app/Reward/Paging`,

  // 活动详情
  rewardGet: `${host}/api/services/app/Reward/Get`,

  // 添加或者修改的公共方法
  rewardCreateOrUpdate: `${host}/api/services/app/Reward/CreateOrUpdate`,

  // 设置失效
  rewardSetInvalid: `${host}/api/services/app/Reward/SetInvalid`,

  // 删除
  rewardDel: `${host}/api/services/app/Reward/Del`,

  // 人员奖金管理
  employeeWalletPaging: `${host}/api/services/app/Employee/EmployeeWalletPaging`,

  // 人员奖金管理导出列表
  downEmployeeWalletReport: `${host}/api/services/app/Employee/DownEmployeeWalletReport`,

  // 奖励流水
  billPaging: `${host}/api/services/app/Employee/BillPaging`,

  // 人员管理
  employeeEmployeePaging: `${host}/api/services/app/Employee/EmployeePaging`,

  // 人员管理导出列表
  downEmployeeReport: `${host}/api/services/app/Employee/DownEmployeeReport`,

  // 设置用户状态
  employeeSetState: `${host}/api/services/app/Employee/SetState`,

  // 获取服务器时间
  getCurrentTime: `${host}/api/services/app/Attach/GetCurrentTime`,

  // 删除表单
  statementSettingDelete: `${host}/api/services/app/StatementSetting/Delete`,

  // 新建发放表单
  statementSettingCreate: `${host}/api/services/app/StatementSetting/Create`,

  // 获取发放表单
  statementSettingGet: `${host}/api/services/app/StatementSetting/Get`,

  // 更新发放表单
  statementSettingUpdate: `${host}/api/services/app/StatementSetting/Update`,

  // 发放表单列表
  statementSettingPaging: `${host}/api/services/app/StatementSetting/Paging`,

  // 更新订阅消息设置
  subscribeMessageSettingUpdate: `${host}/api/services/app/SubscribeMessageSetting/Update`,

  // 获取阅消息设置信息
  subscribeMessageSettingGet: `${host}/api/services/app/SubscribeMessageSetting/Get`,

  // 获取阅消息设置信息列表
  subscribeMessageSettingGetList: `${host}/api/services/app/SubscribeMessageSetting/GetList`,

  // 发放报表
  billStatementPaging: `${host}/api/services/app/BillStatement/Paging`,

  // 审核列表
  billStatementDetailPaging: `${host}/api/services/app/BillStatementDetail/Paging`,

  // 审核
  billStatementDetailExamine: `${host}/api/services/app/BillStatementDetail/Examine`,

  // 完成审核
  billStatementCompleteExamine: `${host}/api/services/app/BillStatement/CompleteExamine`,

  // 预览审核完成数据
  billStatementPreview: `${host}/api/services/app/BillStatement/Preview`,

  // 发送中,已完成 概况
  billStatementGetOverView: `${host}/api/services/app/BillStatement/GetOverView`,

  // 重新发起
  billStatementRePay: `${host}/api/services/app/BillStatement/RePay`,

  // 线上发
  billStatementDetailPay2Wallets: `${host}/api/services/app/BillStatementDetail/Pay2Wallets`,

  // 线下发
  billStatementDetailOffLinePay: `${host}/api/services/app/BillStatementDetail/OffLinePay`,

  // 支付中或支付完成列表
  billStatementDetailPayPaging: `${host}/api/services/app/BillStatementDetail/PayPaging`,

  // 人员钱包
  employeeGetWallet: `${host}/api/services/app/Employee/GetWallet`,

  // 审核列表报表
  billStatementDetailPagingReport: `${host}/api/services/app/BillStatementDetail/PagingReport`,

  // 发放报表
  billStatementPagingReport: `${host}/api/services/app/BillStatement/PagingReport`,

  // 支付中或支付完成列表报表
  billStatementDetailPayPagingReport: `${host}/api/services/app/BillStatementDetail/PayPagingReport`,

  // 发放方案报表
  statementSettingPagingReport: `${host}/api/services/app/StatementSetting/PagingReport`,

  // 创建试题
  createQuestionBankAsync: `${host}/api/services/app/QuestionBank/CreateQuestionBankAsync`,

  // 编辑试题
  updateQuestionBankAsync: `${host}/api/services/app/QuestionBank/UpdateQuestionBankAsync`,

  // 获取更新试题的数据
  getQuestionBankForEdit: `${host}/api/services/app/QuestionBank/GetQuestionBankForEdit`,

  // 获取分页列表试题的数据
  questionBanksPaging: `${host}/api/services/app/QuestionBank/QuestionBanksPaging`,

  // 删除试题
  questionBankDelete: `${host}/api/services/app/QuestionBank/Delete`,

  // 创建视频库
  createVideoBankAsync: `${host}/api/services/app/VideoBank/CreateVideoBankAsync`,

  // 编辑视频库
  updateVideoBankAsync: `${host}/api/services/app/VideoBank/UpdateVideoBankAsync`,

  // 创建或编辑视频库
  createOrUpdateVideoBank: `${host}/api/services/app/VideoBank/CreateOrUpdateVideoBank`,

  // 获取分页列表视频库的数据
  videoBanksPaging: `${host}/api/services/app/VideoBank/VideoBanksPaging`,

  // 获取更新视频库的数据
  getVideoBankForEdit: `${host}/api/services/app/VideoBank/GetVideoBankForEdit`,

  // 删除视频库
  videoBankDelete: `${host}/api/services/app/VideoBank/Delete`,

  // 创建或编辑考卷
  createOrUpdateExam: `${host}/api/services/app/Exam/CreateOrUpdateExam`,

  // 获取更新考卷的数据
  getExamForEdit: `${host}/api/services/app/Exam/GetExamForEdit`,

  // 删除考卷
  examDelete: `${host}/api/services/app/Exam/Delete`,

  // 获取分页列表试卷的数据
  papersPaging: `${host}/api/services/app/Paper/PapersPaging`,

  // 获取更新试卷的数据
  getPaperForEdit: `${host}/api/services/app/Paper/GetPaperForEdit`,

  // 创建或编辑试卷
  createOrUpdatePaper: `${host}/api/services/app/Paper/CreateOrUpdatePaper`,

  // 删除试卷
  paperDelete: `${host}/api/services/app/Paper/Delete`,

  // 设置终止
  paperSetInvalid: `${host}/api/services/app/Paper/SetInvalid`,

  // 批量创建或编辑试题
  createOrUpdatePaperDetailList: `${host}/api/services/app/PaperDetail/CreateOrUpdatePaperDetailList`,

  // 创建试卷
  createPaperAsync: `${host}/api/services/app/Paper/CreatePaperAsync`,

  // 编辑试卷
  updatePaperAsync: `${host}/api/services/app/Paper/UpdatePaperAsync`,

  // 获取试卷全部试题
  getAllByPaperId: `${host}/api/services/app/PaperDetail/GetAllByPaperId`,

  // 编辑试题
  updatePaperDetailAsync: `${host}/api/services/app/PaperDetail/UpdatePaperDetailAsync`,

  // 获取考试统计数据
  examGetExamPass: `${host}/api/services/app/Exam/GetExamPass`,

  // 获取分页列表考卷的数据(单考试卷)
  examsPaging: `${host}/api/services/app/Exam/ExamsPaging`,

  // 获取分页列表多次考卷的数据(复考试卷)
  moreExamsPaging: `${host}/api/services/app/Exam/MoreExamsPaging`,

  // 导出员工考卷(单考试卷)
  downExamsReport: `${host}/api/services/app/Exam/DownExamsReport`,

  // 导出员工考卷(复考试卷)
  downMoreExamsReport: `${host}/api/services/app/Exam/DownMoreExamsReport`,

  // 获取AccessKey
  getAliOss: `${host}/api/services/app/Attach/GetAliOss`,

  // 线下发放
  offLineCompleteExamine: `${host}/api/services/app/BillStatement/OffLineCompleteExamine`,

  // 获取分页列表题类型的数据
  questionTypePaging: `${host}/api/services/app/QuestionType/QuestionTypePaging`,

  // 获取更新题类型的数据
  getQuestionTypeForEdit: `${host}/api/services/app/QuestionType/GetQuestionTypeForEdit`,

  // 创建或编辑题类型
  createOrUpdateQuestionType: `${host}/api/services/app/QuestionType/CreateOrUpdateQuestionType`,

  // 删除题类型
  questionTypeDelete: `${host}/api/services/app/QuestionType/Delete`,

  // 获取题类型的全部数据
  getQuestionTypeAll: `${host}/api/services/app/QuestionType/GetQuestionTypeAll`,

  // 获取考题分析的数据
  analyzeExamDetailsPaging: `${host}/api/services/app/ExamDetail/AnalyzeExamDetailsPaging`,

  // 获取答题详情
  examDetailsByEmployee: `${host}/api/services/app/ExamDetail/ExamDetailsByEmployee`,

  // 考试完成列表
  examPayPaging: `${host}/api/services/app/BillStatementDetail/ExamPayPaging`,

  // 考试完成列表导出
  examPayReportPaging: `${host}/api/services/app/BillStatementDetail/ExamPayReportPaging`,

  // 员工管理 - 管理人员 - 列表
  managerPaging: `${host}/api/services/app/user/userPaging`,

  // 员工管理 - 管理人员 -删除
  managerDelete: `${host}/api/services/app/User/Delete`,

  // 员工管理 - 管理人员 - 个人信息
  // managerInfo: `${host}/api/services/app/user/get`,
  managerInfo: `${host}/api/services/app/User/Get`,

  // 员工管理 - 管理人员 - 个人信息 - 更新
  // managerUpdate: `${host}/api/services/app/user/update`,
  managerUpdate: `${host}/api/services/app/User/Update`,

  // 员工管理 - 管理人员 - 个人信息 - 新建
  // managerCreate: `${host}/api/services/app/user/create`,
  managerCreate: `${host}/api/services/app/User/Create`,

  // 设置 - 权限管理 - 职位列表
  rolePaging: `${host}/api/services/app/role/GetAllRole`,

  // 设置 - 权限管理 - 职位列表 - 删除职位
  roleDelete: `${host}/api/services/app/role/Delete`,

  // 设置 - 权限管理 - 编辑/新建 - 职位信息
  roleInfo: `${host}/api/services/app/role/get`,

  // 设置 - 权限管理 - 编辑/新建 - 权限树
  rolePermissionsTree: `${host}/api/services/app/role/getAllPermissionsTree`,

  // 设置 - 登录 - 获取权限
  rolePermissions: `${host}/api/services/app/user/getUserAllPermissions`,

  // 设置 - 权限管理 - 职位信息 - 更新
  roleInfoUpdate: `${host}/api/services/app/role/update`,

  // 设置 - 权限管理 - 职位信息 - 新建
  roleInfoCreate: `${host}/api/services/app/role/create`,

  // 设置 - 部门管理 - 获取全部部门树
  getDepartmentTree: `${host}/api/services/app/Department/GetDepartmentTree`,

  // 设置 - 部门管理 - 编辑/新建部门
  departmentUpdate: `${host}/api/services/app//department/createOrUpdateDepartment`,

  // 设置 - 部门管理 - 根据父Id获取某级
  departmentByParentId: `${host}/api/services/app/department/getListByParentId`,

  // 设置 - 部门管理 - 删除部门
  departmentDelete: `${host}/api/services/app/department/delete`,

  // 获取组织架构
  getOrganizationTree: `${host}/api/services/app/Department/GetOrganizationDepartmentTree`,

  // 获取城市数据
  getAreaList: `${host}/api/services/app/Area/GetAreaList`,

  // 添加/修改普通员工
  employeeEdit: `${host}/api/services/app/Employee/CreateOrUpdate`,

  // 获取区域业务员
  getLinkmanList: `${host}/api/services/app/Employee/GetLinkmanList`,

  // 获取通用设置列表
  getGeneralSettingPaging: `${host}/api/services/app/GeneralSetting/Paging`,

  // 删除通用设置
  deleteGeneralSetting: `${host}/api/services/app/GeneralSetting/DelGeneralSetting`,

  // 新建通用设置
  createGeneralSetting: `${host}/api/services/app/GeneralSetting/InsertGeneralSetting`,

  // 编辑通用设置
  updateGeneralSetting: `${host}/api/services/app/GeneralSetting/UpdateGeneralSetting`,

  // 自定义奖励列表
  getPayReward: `${host}/api/services/app/PayReward/Paging`,

  // 新建自定义奖励
  createpayReward: `${host}/api/services/app/PayReward/Create`,

  // 删除自定义奖励
  deletePayReward: `${host}/api/services/app/PayReward/Delete`,

  // 下载模版
  payRewardDewoTemplate: `${host}/api/services/app/PayReward/DownTemplate`,

  // 获取自定义奖励详情
  getPayRewardDetails: `${host}/api/services/app/PayReward/Get`,

  // 获取自定义奖励详情的列表
  getPayRewardDetailsList: `${host}/api/services/app/EmployeePayReward/Paging`,

  // 上传文件
  uploadExcel: `${host}/api/services/app/Attach/UploadExcel`,

  // 自定义奖励 - 奖励详情 - 重新发送
  payRewardResend: `${host}/api/services/app/EmployeePayReward/RePay`,

  // 自定义奖励 - 奖励详情 - 不发送
  payRewardNoSend: `${host}/api/services/app/EmployeePayReward/NotPay`,

  // 自定义奖励 - 奖励详情 - 导出
  payRewardExport: `${host}/api/services/app/EmployeePayReward/Report`,

  // 获取区域促销队长
  getPromotionList: `${host}/api/services/app/WeixinEmployee/GetPromotionList`,

  // 管理人员重置密码
  resetPassword: `${host}/api/services/app/User/ResetPassword`,

  // 获取固定城市
  getFixedAreaList: `${host}/api/services/app/Area/GetFixedSecondAreaList`,

  // 获取奶站信息
  getDairyInfo: `${host}/api/services/app/Dairy/GetForEdit`,


  //----------------客户相关
  //导入列表页
  customersImportPaging: `${host}/api/services/app/Customers/CustomersImportPaging`,
  //导入详情页
  customersImportDetailPaging: `${host}/api/services/app/Customers/CustomersImportDetailPaging`,
  //下载源文件
  downSourceFile: `${host}/api/services/app/Customers/DownSourceFile`,

  //导入
  createCustomerImport: `${host}/api/services/app/Customers/CreateCustomerImport`,
  //下载客户导入模板
  downTemplate: `${host}/api/services/app/Customers/DownTemplate`,
  //更新客户信息
  updateCustomerManage: `${host}/api/services/app/Customers/UpdateCustomerManage`,

  //新增客户
  createCustomerManage: `${host}/api/services/app/Customers/CreateCustomerManage`,
  //客户详情
  getCustomersDetail: `${host}/api/services/app/Customers/GetCustomersManageDetail`,
  //分页查询
  customersManagerPaging: `${host}/api/services/app/Customers/CustomersManagerPaging`,
  // 获取日历（工作日/非工作日）
  getWeekdayList: `${host}/api/services/app/Weekdays/WeekdayList`,

  // 设置日历（工作日/非工作日）
  setWeekday: `${host}/api/services/app/Weekdays/SetWeekday`,

  // 订单列表
  ordersOrderPaging: `${host}/api/services/app/Orders/OrderPaging`,

  // 订单列表
  ordersOrderPaging_Search: `${host}/api/services/app/Orders/OrderPaging_Search`,

  // 订单详情
  ordersGetOrderDetail: `${host}/api/services/app/Orders/GetOrderDetail`,

  // 根据关键字返回奶站列表（不传返回全部）
  getDairysByName: `${host}/api/services/app/Dairy/GetDairysByName`,

  // 下载订单报表
  ordersDownOrderReport: `${host}/api/services/app/Orders/DownOrderReport`,

  // 下载订单报表
  ordersDownOrderReport_Search: `${host}/api/services/app/Orders/DownOrderReport_Search`,

  // 添加备注
  setOrderBusinessRemark: `${host}/api/services/app/Orders/SetOrderBusinessRemark`,

  // 订单对接 新增
  orderStatisticNew: `${host}/api/services/app/Orders/OrderStatisticNew`,

  // 订单对接 暂停恢复(变化)
  orderStatisticAction: `${host}/api/services/app/Orders/OrderStatisticAction`,

  // 订单对接 退款
  orderStatisticRefund: `${host}/api/services/app/Orders/OrderStatisticRefund`,

  // 订单对接 新增,三元
  orderStatisticNew_Sanyuan: `${host}/api/services/app/Orders/OrderStatisticNew_Sanyuan`,

  // 订单对接 暂停恢复(三元)
  orderStatisticAction_Sanyuan: `${host}/api/services/app/Orders/OrderStatisticAction_Sanyuan`,

  // 订单对接 退款 三元
  orderStatisticRefund_Sanyuan: `${host}/api/services/app/Orders/OrderStatisticRefund_Sanyuan`,

  // 订单对接 三元 根据关键字返回奶站id，名称，地址（不传返回全部）
  pagingDairysByNameSimpleWithAddress: `${host}/api/services/app/Dairy/PagingDairysByNameSimpleWithAddress`,

  // 订单对接 三元 指派奶站
  setDesignatedDairy: `${host}/api/services/app/Orders/SetDesignatedDairy`,

  // 订单对接 三元 系统指派确认
  getSetDesignatedDairyState: `${host}/api/services/app/Orders/GetSetDesignatedDairyState`,

  // 订单列表 三元
  orderPaging_Sanyuan: `${host}/api/services/app/Orders/OrderPaging_Sanyuan`,

  // 下载订单报表 三元
  downOrderReport_Sanyuan: `${host}/api/services/app/Orders/DownOrderReport_Sanyuan`,

  //  订单对接 退订 三元
  orderStatisticUnsubscribe_Sanyuan: `${host}/api/services/app/Orders/OrderStatisticUnsubscribe_Sanyuan`,

  // 防疫-列表
  antiepidemicPaging: `${host}/api/services/app/Antiepidemic/Paging`,

  // 防疫-导出
  antiepidemicDownReport: `${host}/api/services/app/Antiepidemic/DownReport`,

  // 防疫-详情
  antiepidemicGet: `${host}/api/services/app/Antiepidemic/Get`,

  // 防疫-审核
  antiepidemicAudit: `${host}/api/services/app/Antiepidemic/Audit`,


  //----------------------区域、经销商、奶站、奶工
  //获取区域根据数据权限
  getOrganizationArea_Page: `${host}/api/services/app/Organizations/GetOrganizationArea_Page`,
  //获取经销商
  getOrganizationSeller_Page: `${host}/api/services/app/Organizations/GetOrganizationSeller_Page`,
  //获取奶站
  getDairyByOrganizationId: `${host}/api/services/app/Dairy/GetDairyByOrganizationId`,
  //根据奶站获取其下奶工
  getEmployeeByDairyId: `${host}/api/services/app/Employee/GetEmployeeByDairyId`,

  //---------------------------员工
  //数据权限列表
  getDataPermissionList: `${host}/api/services/app/DataPermissions/GetDataPermissionList`,
  //组织树
  getOrganizationList: `${host}/api/services/app/Organizations/GetOrganizationList`,
  //职务
  getTitleListByOrganizationId: `${host}/api/services/app/Titles/GetTitleListByOrganizationId`,

  //---------------------------奶站
  //奶站列表
  pagingGetDairy: `${host}/api/services/app/Dairy/PagingGetDairy`,
  //获取业务员
  getLinkmanList_Page: `${host}/api/services/app/Employee/GetLinkmanList_Page`,
  //奶站新增或者修改
  createOrUpdate: `${host}/api/services/app/Dairy/CreateOrUpdate`,
  //奶站下载报表
  downloadDairy: `${host}/api/services/app/Dairy/DownloadDairy`,

  // change Li start ----------------------------------------------------------------------------------------------------
  // 组织管理 start
  // 获取组织列表 get
  getOrganizationList: `${host}/api/services/app/Organizations/GetOrganizationList`,

  // 显示组织和其下的奶站
  getDairyWithTree: `${host}/api/services/app/Dairy/GetDairyWithTree`,

  // 获取组织类型EnumOrganizationType  get
  getOrganizationTypes: `${host}/api/services/app/Organizations/GetOrganizationTypes`,

  // 创建或更新组织 post
  createOrUpdateOrganization: `${host}/api/services/app/Organizations/CreateOrUpdateOrganization`,

  // 删除，已绑定员工的不能删除（目前暂时都能删除） delete
  delete: `${host}/api/services/app/Organizations/Delete`,

  // 获取指定id组织
  getOrganizationById: `${host}/api/services/app/Organizations/GetOrganizationById`,

  // 获取子级
  getOrganizationSonById: `${host}/api/services/app/Organizations/GetOrganizationSonById`,

  // 职位管理 start ------------------------------------------------
  // 获取职位
  getTitleList: `${host}/api/services/app/Titles/GetTitleList`,

  // 根据id获取职位
  getTitleById: `${host}/api/services/app/Titles/GetTitleById`,

  // 根据部门获取职位
  getTitleListByOrganizationId: `${host}/api/services/app/Titles/GetTitleListByOrganizationId`,

  // 创建或更新职位
  createOrUpdateTitle: `${host}/api/services/app/Titles/CreateOrUpdateTitle`,

  // 删除
  titlesDel: `${host}/api/services/app/Titles/Delete`,

  // 根据名称搜索职位
  getTitleByName: `${host}/api/services/app/Titles/GetTitleByName`,

  // 数据权限 start ------------------------------------------------------------------------------------
  // 获取数据权限
  // getDataPermissionList: `${host}/api/services/app/DataPermissions/GetDataPermissionList`,

  // 创建或更新数据权限
  createOrUpdateDataPermission: `${host}/api/services/app/DataPermissions/CreateOrUpdateDataPermission`,

  // 数据权限删除
  dataPermissionsDel: `${host}/api/services/app/DataPermissions/Delete`,

  // 根据id获取数据权限
  getDataPermissionById: `${host}/api/services/app/DataPermissions/GetDataPermissionById`,


  // change Li end ----------------------------------------------------------------------------------------------------


  // 设置 -- 消息管理 -- 消息推送 开始
  // 消息通知列表
  messageNoticesPaging: `${host}/api/services/app/MessageNotices/MessageNoticesPaging`,
  // 消息推送列表 删除
  messageNoticesDel: `${host}/api/services/app/MessageNotices/Delete`,
  // 创建或更新 消息通知
  createOrUpdateMessageNotice: `${host}/api/services/app/MessageNotices/CreateOrUpdateMessageNotice`,
  // 获取消息通知
  getMessageNoticeById: `${host}/api/services/app/MessageNotices/GetMessageNoticeById`,
  // 终止列表
  messageNoticesSetStop: `${host}/api/services/app/MessageNotices/SetStop`,
  // 设置 -- 消息管理 -- 消息推送 结束


  // 获取组织列表 新改
  getOrganizationAreaSellerList: `${host}/api/services/app/Organizations/GetOrganizationAreaSellerList`,

  // 考试 -- 试题导入 开始
  // 试题库导入列表
  questionBankImportPaging: `${host}/api/services/app/QuestionImport/QuestionBankImportPaging`,
  // 试题库导入详情
  questionBankImportDetailPaging: `${host}/api/services/app/QuestionImport/QuestionBankImportDetailPaging`,
  // 试题库导入
  questionBankImport: `${host}/api/services/app/QuestionImport/QuestionBankImport`,
  // 下载客户导入模板
  questionImportDownTemplate: `${host}/api/services/app/QuestionImport/DownTemplate`,
  // 下载源文件
  questionImportDownSourceFile: `${host}/api/services/app/QuestionImport/DownSourceFile`,
  // 考试 -- 试题导入 结束



  // 员工 -- 普通员工 打卡设置 开始
  // 防疫调查
  surveyPaging: `${host}/api/services/app/Antiepidemic/SurveyPaging`,
  // 防疫调查 详情
  getSurveyDetail: `${host}/api/services/app/Antiepidemic/GetSurveyDetail`,
  // 创建行程
  createSurvey: `${host}/api/services/app/Antiepidemic/CreateSurvey`,
  // 更新行程调查
  updateSurvey: `${host}/api/services/app/Antiepidemic/UpdateSurvey`,

  // 员工 -- 普通员工 打卡设置 结束


  // 订单 -- 小区管理  开始
  // 获取小区
  communityList: `${host}/api/services/app/Communitys/CommunityList`,
  // 创建或编辑
  createOrUpdateCommunity: `${host}/api/services/app/Communitys/CreateOrUpdateCommunity`,
  // 小区详情
  getForEditCommunitys: `${host}/api/services/app/Communitys/GetForEdit`,
  // 小区导出
  downloadCommunity: `${host}/api/services/app/Communitys/DownloadCommunity`,

  //  小区反馈

  // 指派反馈的社区
  setDesignatedCommunity: `${host}/api/services/app/Communitys/SetDesignatedCommunity`,
  // 获取反馈小区
  communityFeedbackList: `${host}/api/services/app/CommunityFeedback/CommunityFeedbackList`,

  // 订单 -- 小区管理  开始

  // 资料 -- start /api/services/app/
  // 创建资料
  createMeansAsync: `${host}/api/services/app/Means/CreateMeansAsync`,

  // 获取分页列表资料的数据
  meansPaging: `${host}/api/services/app/Means/MeansPaging`,

  // 获取更新资料的数据 
  getMeansForEdit: `${host}/api/services/app/Means/GetMeansForEdit`,

  // 编辑资料
  updateMeansAsync: `${host}/api/services/app/Means/UpdateMeansAsync`,

  // 设置下架
  setInvalid: `${host}/api/services/app/Means/SetInvalid`,

  // 设置上架
  setValid: `${host}/api/services/app/Means/SetValid`,

  // 删除资料
  meansDelete: `${host}/api/services/app/Means/Delete`,

  // 获取分页列表视频库的数据,资料专用
  videoBanksPagingForMeans: `${host}/api/services/app/VideoBank/VideoBanksPagingForMeans`,

  // 资料 -- end

  // 订单对接 新增,三元日配奶
  orderStatisticNew_SanyuanDaily: `${host}/api/services/app/Orders/OrderStatisticNew_SanyuanDaily`,

  // 订单对接 暂停恢复，三元日配奶
  orderStatisticAction_SanyuanDaily: `${host}/api/services/app/Orders/OrderStatisticAction_SanyuanDaily`,

  // 订单对接 退款 三元日配奶
  orderStatisticRefund_SanyuanDaily: `${host}/api/services/app/Orders/OrderStatisticRefund_SanyuanDaily`,

  // 订单对接 退订列表，三元日配奶
  orderStatisticUnsubscribe_SanyuanDaily: `${host}/api/services/app/Orders/OrderStatisticUnsubscribe_SanyuanDaily`,

  // 订单对接 订单列表详情，三元日配奶
  orderPaging_SanyuanDaily: `${host}/api/services/app/Orders/OrderPaging_SanyuanDaily`,

  // 订单对接 订单导出，三元日配奶
  downOrderReport_SanyuanDaily: `${host}/api/services/app/Orders/DownOrderReport_SanyuanDaily`,

  // 观看纪录 
  watchRecordPaging: `${host}/api/services/app/Means/WatchRecordPaging`,

  // 观看纪录导出
  downWatchRecordReport: `${host}/api/services/app/Means/DownWatchRecordReport`,

  // 推广列表 
  poularizePaging: `${host}/api/services/app/Popularize/Paging`,

  // 新增推广内容 
  createOrEditPoularize: `${host}/api/services/app/Popularize/CreateOrEditPoularize`,

  // 推广内容查看/编辑 
  getPoularize: `${host}/api/services/app/Popularize/GetPoularize`,

  // 删除推广 
  deletePopularize: `${host}/api/services/app/Popularize/DeletePopularize`,

  // 推广审核列表 
  popularizeExaminePaging: `${host}/api/services/app/PopularizeExamine/PopularizeExaminePaging`,

  // 审核详情 
  getExamineDetail: `${host}/api/services/app/PopularizeExamine/GetExamineDetail`,

  // 审核驳回 
  examineReject: `${host}/api/services/app/PopularizeExamine/ExamineReject`,

  // 审核通过 
  examineSuccess: `${host}/api/services/app/PopularizeExamine/ExamineSuccess`,

  // 审核导出 
  downloadPopularizeExamineReport: `${host}/api/services/app/PopularizeExamine/DownloadPopularizeExamineReport`,

  // 数据统计导出
  downloadStatisticsReport: `${host}/api/services/app/PopularizeStatistics/DownloadStatisticsReport`,

  // 数据统计列表 
  popularizeStatisticsPaging: `${host}/api/services/app/PopularizeStatistics/PopularizeStatisticsPaging`,

  // 员工推广详情 
  employeePopularizePaging: `${host}/api/services/app/PopularizeStatistics/EmployeePopularizePaging`,

  // 终止 
  popularizeStop: `${host}/api/services/app/Popularize/Stop`,

  // 推广期数 
  getPeriodCountStrList: `${host}/api/services/app/PopularizeStatistics/GetPeriodCountStrList`,



}

export default config

import { observable, action } from 'mobx';

class MilkStation {

  @observable.ref visible = false;

  @observable.ref milkSite = false;

  @observable.ref edit = false;

  @observable.ref dataLine = {};

  @observable.ref name = '';

  @observable.ref city = '';

  @action resetDataLine(data) {
    this.dataLine = data || {}
  }

}

export default MilkStation
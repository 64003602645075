import { get, post, put, del } from '../../http/http';
import config from '../../config/api';

class Staff {

  // 获取个人信息
  getEmployee(params) {
    return get(config.getEmployee, params)
  }

  // 人员管理
  employeeEmployeePaging(params) {
    return post(config.employeeEmployeePaging, params)
  }

  // 人员管理导出列表
  downEmployeeReport(params) {
    return post(config.downEmployeeReport, params)
  }

  // 设置用户状态
  employeeSetState(params) {
    return post(config.employeeSetState, params)
  }

  // 删除用户
  employeeDelEmployee(params) {
    return post(config.employeeDelEmployee, params)
  }

  // 添加/修改
  employeeEdit(params) {
    return post(config.employeeEdit, params)
  }

  // 获取区域促销队长
  getPromotionList(params) {
    return get(config.getPromotionList, params)
  }

  // 获取区域
  getFixedAreaList(params) {
    //return get(config.getFixedAreaList, params)
    return get(config.getOrganizationArea_Page, params)
  }

  // 所在奶站
  pagingGetSecond(params) {
    //return post(config.pagingGetSecond, params)
    return get(config.getDairyByOrganizationId, params)
  }

  // 所在经销商
  getFirstAll(params) {
    //return get(config.getFirstAll, params)
    return get(config.getOrganizationSeller_Page, params)
  }
  // -------------管理人员-------------
  // 获取管理人员列表
  getManagerPaging(params) {
    return post(config.managerPaging, params)
  }

  // 删除管理人员
  deleteManager(params) {
    return del(config.managerDelete, params)
  }

  // 获取管理人员用户信息
  getManagerInfo(params) {
    return get(config.managerInfo, params)
  }

  // 更新管理人员用户信息
  updateManagerInfo(params) {
    return put(config.managerUpdate, params)
  }

  // 创建管理人员用户信息
  createManagerInfo(params) {
    return post(config.managerCreate, params)
  }

  // 获取区域业务员
  getLinkmanList(params) {
    return get(config.getLinkmanList, params)
  }

  // 重置密码
  resetPassword(params) {
    return post(config.resetPassword, params)
  }

  // -------------组织架构-------------
  // 获取组织架构左侧树
  getOrganizationTree(params) {
    return get(config.getOrganizationTree, params)
  }

  // 防疫-列表
  antiepidemicPaging(params) {
    return post(config.antiepidemicPaging, params)
  }

  // 防疫-导出
  antiepidemicDownReport(params) {
    return post(config.antiepidemicDownReport, params)
  }

  // 防疫-详情
  antiepidemicGet(params) {
    return get(config.antiepidemicGet, params)
  }

  // 防疫-审核
  antiepidemicAudit(params) {
    return post(config.antiepidemicAudit, params)
  }


  //--------------新增接口
  //数据权限
  getDataPermissionList(params) {
    return get(config.getDataPermissionList, params)
  }

  //获取组织树
  getOrganizationList(params) {
    return get(config.getOrganizationList, params)
  }

  //根据组织树获取职位
  getTitleListByOrganizationId(params){
    return get(config.getTitleListByOrganizationId, params)
  }


  // 防疫调查
  surveyPaging(params){
    return post(config.surveyPaging, params)
  }

  // 防疫调查 详情
  getSurveyDetail(params){
    return get(config.getSurveyDetail, params)
  }

  // 创建行程
  createSurvey(params){
    return post(config.createSurvey, params)
  }

  // 更新行程调查
  updateSurvey(params){
    return put(config.updateSurvey, params)
  }

}

export {
  Staff
}

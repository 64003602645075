// 测试地址
// const host = 'https://testsanyuan.shundaonetwork.com'

// 线上地址
const host = 'https://sanyuan.shundaonetwork.com'


// 公司个人本地服务
// const host = 'http://localhost:21021'

module.exports = host

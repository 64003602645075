import { get, post } from '../../http/http';
import config from '../../config/api';

class LoginOrRegister {

  // 获取验证码
  getCode(params) {
    return get(config.getCheckCode, params)
  }

  // 登录
  authenticate(params) {
    return post(config.authenticate, params)
  }

  // 注册
  registerByPhoneNumber(params) {
    return post(config.registerByPhoneNumber, params)
  }

  // 找回密码
  changePasswordByCode(params) {
    return post(config.changePasswordByCode, params)
  }

  // 获取权限
  getPermisstions(params) {
    return get(config.rolePermissions, params)
  }

  // 修改密码
  changePassword(params) {
    return post(config.changePassword, params)
  }

}

export {
  LoginOrRegister
}
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Popover, Button } from 'antd';
import { isLogout, ifPermissions } from '../../utils/utils';
import RouterMap from './routerMap';
import ChangePassword from './changePassword';
import './root.less';

@inject("todo",
  'title',
  'router',
  'staff',
  'finance',
  'milkStation',
  'exam')
@observer

class Root extends React.Component {
  constructor(props, context) {
    super()
    this.state = {
      secondMenu: [],
      changePasswordModal: false,
    }
    this.userName = JSON.parse(sessionStorage.getItem('Jurisdiction')).userName
  }

  componentDidMount = () => {
    isLogout(this)
    let routerArr = this.props.router.routerArr
    let ary = this.props.history.location.pathname.split('/')
    let root = '/' + ary[1]
    let root2 = '/' + ary[1] + '/' + ary[2]
    this.setCurrentSelect(routerArr, root, root2)
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    isLogout(this)
    let routerArr = this.props.router.routerArr
    let ary = nextProps.history.location.pathname.split('/')
    let root = '/' + ary[1]
    let root2 = '/' + ary[1] + '/' + ary[2]
    this.setCurrentSelect(routerArr, root, root2)
  }

  // 设置当前选中(一级目录)
  setCurrentSelect = (arr, root, root2) => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].select = false
      if (arr[i].root === root) {
        arr[i].select = true;
        this.setState({
          secondMenu: this.filterExamSecound(arr[i])
        }, () => {
          this.setCurrentSelectSecond(arr, root2)
        })
      }
    }
    if (this.parseValue(arr)) {
      this.props.router.replaceArr(arr)
    } else {
      this.props.history.replace('/404')
    }
  }

  // 设置当前选中(二级目录)
  setCurrentSelectSecond = (arr, root2) => {
    let secondMenu = this.state.secondMenu
    for (let i = 0; i < secondMenu.length; i++) {
      secondMenu[i].select = false
      secondMenu[i].open = true
      if (secondMenu[i].path) {
        if (secondMenu[i].root === root2) {
          secondMenu[i].select = true
          this.setState({
            secondMenu: secondMenu
          })
        }
      } else if (secondMenu[i].children) {
        for (let j = 0; j < secondMenu[i].children.length; j++) {
          secondMenu[i].children[j].select = false
          if (secondMenu[i].children[j].root === root2) {
            secondMenu[i].children[j].select = true
            this.setState({
              secondMenu: secondMenu
            })
          }
        }
      }
    }
    if (secondMenu.length) {
      if (this.parseValue(secondMenu)) {
        this.props.router.replaceArr(arr)
      } else {
        this.props.history.replace('/404')
      }
    }
  }

  // 判断有无选择中路由
  parseValue = (item) => {
    return item.some((element) => {
      if (element.select) {
        return true
      } else if (!element.select && element.children && element.children.length > 0) {
        return this.parseValue(element.children)
      } else {
        return false
      }
    })
  }

  // 跳转到一级路由
  handleToFirstLink = (path) => {
    if (this.props.history.location.pathname === path) {
      return
    }
    this.props.history.push(path)
  }

  // 跳转到二级路由
  handleToSecondLink = (path) => {
    if (this.props.history.location.pathname === path) {
      return
    }
    this.props.history.push(path)
  }

  // 展开关闭子菜单
  handleToSelectIcon = (type, index) => {
    let secondMenu = this.state.secondMenu
    for (let i = 0; i < secondMenu.length; i++) {
      secondMenu[index].open = !type
    }
    this.setState({
      secondMenu: secondMenu,
    })
  }

  // 退出登录
  handleToExit = () => {
    sessionStorage.removeItem('Jurisdiction')
    sessionStorage.removeItem('permissions')
    this.props.history.replace('/')
  }

  // 判断一级导航的权限
  filterRouter = () => {
    const routerArr = this.props.router.routerArr
    let arr = []
    for (let menu of routerArr) {
      if (menu.children) {
        for (let item of menu.children) {
          if (ifPermissions(item.permissions)) {  //判断当前一级导航下的二级导航是否有权限
            menu.path = item.path
            arr.push(menu)
            break
          }
        }
      }
    }
    return arr
  }

  // 判断二级导航的权限
  filterExamSecound = (router) => {
    if (router.root === '/set' && this.userName !== 'admin') { //只有admin账号有通用设置的权限
      router.children.splice(6, 1)
    }
    return router.children.filter(item => ifPermissions(item.permissions) || item.title)
  }

  // 修改密码
  openChangePassword = () => {
    this.setState({
      changePasswordModal: true
    })
  }

  // 关闭修改密码
  closeChangePassword = () => {
    this.setState({
      changePasswordModal: false
    })
  }

  render() {
    const {
      secondMenu,
      changePasswordModal,
    } = this.state
    const content = (
      <div>
        <Button block onClick={() => this.openChangePassword()}>修改密码</Button>
        <Button style={{ marginTop: 10 }} block onClick={() => this.handleToExit()}>退出登录</Button>
      </div>
    )
    return (
      <div className='container'>
        <aside className='aside'>
          <menu className='first-route'>
            <div className='shared-logo' >
              <Popover placement="bottomLeft"
                title={'个人中心'}
                content={content}
                trigger="click"
              >
                <div className='exit'></div>
              </Popover>
            </div>
            {
              this.filterRouter().map((item, index) => {
                return (
                  <div key={index} className={item.select ? 'active first-menu' : 'first-menu'}>
                    <Button
                      type="link"
                      className='buttonNomal'
                      onClick={() => { this.handleToFirstLink(item.path, item.component) }}
                    >
                      <img className='imgIcon' alt='icon' src={item.select ? item.selectIcon : item.icon} />
                      {item.name}
                    </Button>
                  </div>
                )
              })
            }
          </menu>
          {
            secondMenu.length ?
              <menu className='second-route'>
                {
                  secondMenu.map((item, index) => {
                    if (item.title) {
                      return (
                        <div key={index} className='title-top'>
                          {item.title}
                        </div>
                      )
                    }
                    if (item.path) {
                      return (
                        <div key={index} className={item.select ? 'active-second second-menu' : 'second-menu'}>
                          <Button
                            type="link"
                            className='buttonNomal'
                            onClick={() => { this.handleToSecondLink(item.path, item.component) }}
                          >{item.name}</Button>
                        </div>
                      )
                    }
                    if (item.children) {
                      return (
                        <div key={index} className={item.open ? 'open-icon' : 'close-icon'}>
                          <p
                            onClick={() => { this.handleToSelectIcon(item.open, index) }}
                            className='second-group-title'
                          >
                            <i className="arrow-icon"></i>
                            {item.name}
                          </p>
                          <div className='animal-box'>
                            {
                              item.children.map((attr, num) => {
                                return (
                                  <div key={num} className={attr.select ? 'active-thrid thrid-menu' : 'thrid-menu'}>
                                    <Button
                                      type="link"
                                      className='buttonNomal'
                                      onClick={() => { this.handleToSecondLink(attr.path, item.component) }}
                                    >
                                      {attr.name}
                                    </Button>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    }
                    return null
                  })
                }
              </menu> : null
          }
        </aside>
        <div className={secondMenu.length ? 'boxDetail' : 'boxDetailBig'}>
          <RouterMap />
        </div>
        <ChangePassword closeChangePassword={this.closeChangePassword} changePasswordModal={changePasswordModal} />
      </div>
    )
  }
}

export default Root

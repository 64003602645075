import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Root from './pages/router/root';
import GetLost from './pages/getLost/getLost';
import Login from './pages/login';

class App extends React.Component {
  
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/404" component={GetLost} />
          <Route path="/" component={Root} />
          <Redirect from="/" to="/" />
        </Switch>
      </HashRouter>
    )
  }
}

export default App;
import {
  Notify
} from 'zent';

// 获取token
const jurisdiction = function () {
  const res = sessionStorage.getItem("Jurisdiction") || ''
  let result = null
  if (res) {
    let data = JSON.parse(res)
    result = data.accessToken || ''
  }
  return result ? result : ''
}

// 验证手机号是否符合正则
const phoneCodeValid = function (number) {
  if (number) {
    number = Number(myTrim(number, 'g'))
  } else {
    Notify.error('请输入手机号')
    return false
  }
  let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if (!myreg.test(number)) {
    Notify.error('请输入正确手机号')
    return false
  } else {
    return true
  }
}

// 去除字符串中的空格
const myTrim = function (str, global) {
  let result
  if (str) {
    result = str.toString().replace(/(^\s+)|(\s+$)/g, '')
  } else {
    return
  }
  if (global.toLowerCase() === 'g') {
    result = result.replace(/\s/g, '')
  }
  return result
}

// 是否退出登录
const isLogout = function (item) {
  let jurisdiction = JSON.parse(sessionStorage.getItem('Jurisdiction'))
  let permissions = sessionStorage.getItem('permissions')
  if (!(jurisdiction && jurisdiction.accessToken && permissions)) {
    sessionStorage.removeItem('permissions')
    item.props.history.replace('/')
  } else {
    const dateStart = jurisdiction.dateStart
    const dateEnd = new Date().getTime()
    if (dateEnd - dateStart > 1000 * 24 * 60 * 60 || dateEnd - dateStart < 0) {
      Notify.error('登录过期，请重新登录')
      sessionStorage.removeItem('permissions')
      item.props.history.replace('/')
      return false
    }
  }
}

// 根据时间字符串获取年月日时分秒
const getTimeStr = function (str, time) {
  const d = new Date(str)
  const resDate = d.getFullYear() + '-' + parseStar((d.getMonth() + 1)) + '-' + parseStar(d.getDate())
  const resTime = parseStar(d.getHours()) + ':' + parseStar(d.getMinutes()) + ':' + parseStar(d.getSeconds())
  if (time === 0) {
    return resDate + ' 00:00:00'
  } else if (time === 1) {
    return resDate + ' 23:59:59'
  } else {
    return resDate + ' ' + resTime
  }
}

// 获取本周一时间
const getMon = function () {
  let nowTemp = new Date()
  let oneDayLong = 24*60*60*1000
  let c_time = nowTemp.getTime()
  let c_day = nowTemp.getDay() || 7
  let m_time = c_time - (c_day-1)*oneDayLong
  let monday = new Date(m_time)
  let m_year = monday.getFullYear()
  let m_month = monday.getMonth() + 1
  m_month = m_month < 10 ? '0' + m_month : m_month
  let m_date = monday.getDate()
  m_date = m_date < 10 ? '0' + m_date : m_date
  return `${m_year}-${m_month}-${m_date} 00:00:00`
}

// 获取当前时间
const getCurrentDate = function() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day} 23:59:59`;
 }

const parseStar = function (s) {
  return s < 10 ? '0' + s : s
}


// 获取当天时间对象格式(年月日)
const getTimeData = (time) => {
  if (String(time).indexOf('-') > -1) {
    time = time.replace(/-/g, '/')
  }
  let mon = new Date(time).getMonth() + 1
  if (mon < 10) {
    mon = '0' + mon
  }
  let day = new Date(time).getDate()
  if (day < 10) {
    day = '0' + day
  }
  let hour = new Date(time).getHours()
  if (hour < 10) {
    hour = '0' + hour
  }
  let second = new Date(time).getSeconds()
  if (second < 10) {
    second = '0' + second
  }
  let minutes = new Date(time).getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  return {
    year: String(new Date(time).getFullYear()),
    month: String(mon),
    day: String(day),
    hour: String(hour),
    minutes: String(minutes),
    second: String(second),
  }
}

const shallowCopy = (data) => {
  if (Array.isArray(data)) {
    return [].concat(data)
  } else {
    return Object.assign({}, data)
  }
}

// Form表单 验证手机号
const FormPhoneCode = (rule, value) => {
  let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if (!value || myreg.test(value)) {
    return true
  } else {
    return false
  }
}

// Form表单 只允许中文
const FormChinese = (rule, value, min, max) => {
  // let myreg = /^[\u4e00-\u9fa5]*$/
  if (!value) return true
  // if (min && value.length < min) return false
  // if (max && value.length > max) return false
  // if (myreg.test(value)) {
  //   return true
  // } else {
  //   return false
  // }
  if (value.length > min && value.length < max) {
    return true
  } else {
    return false
  }
}

// Form表单 验证只允许输入数字
const FormNumber = (rule, value, min, max) => {
  let myreg = /^[0-9]*$/
  if (!value) return true
  if (min && value.length < min) return false
  if (max && value.length > max) return false
  if (myreg.test(value)) {
    return true
  } else {
    return false
  }
}

// Form表单 只允许中文或数字
const FormChineseNumberEng = (rule, value) => {
  let myreg = /^[0-9a-zA-Z\u4e00-\u9fa5]*$/
  if (!value || myreg.test(value)) {
    return true
  } else {
    return false
  }
}

// Form表单 只能输入数字或字母
const FormNumberEng = (rule, value) => {
  let myreg = /^[0-9a-zA-Z]*$/
  if (!value || myreg.test(value)) {
    return true
  } else {
    return false
  }
}

// Form表单 验证密码必须有数字+字母的组合
const FormPassword = (rule, value) => {
  let myreg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/
  if (!value || myreg.test(value)) {
    return true
  } else {
    return false
  }
}

// 判断权限
const ifPermissions = (current) => {
  const permissionsArr = sessionStorage.getItem('permissions') ? sessionStorage.getItem('permissions').split('~') : ""
  if (Array.isArray(current)) {
    return current.some(item => permissionsArr.indexOf(item) >= 0)
  } else {
    return permissionsArr.indexOf(current) >= 0
  }
}

// 获取星期
const getWeek = (year, month) => {
  year = year || (new Date()).getFullYear();
  month = month || (new Date()).getMonth() + 1;
  let fnToDub = time => time < 10 ? ('0' + time) : +time,
    fnGenerateDate = (time, end) => {
      let j = +time,
        array = [],
        i = 0;
      for (; j <= +end; j++) {
        array.push(j)
      }
      if (array.length < 7) {
        for (; array.length < 7; i++) {
          array.push(i + 1);
        }
      }
      return array;
    };
  const d = new Date();
  d.setFullYear(year, month - 1, 1);
  let w1 = d.getDay() === 0 ? 7 : d.getDay();
  d.setFullYear(year, month, 0);
  const dd = d.getDate();
  const d1 = w1 !== 1 ? 7 - w1 + 2 : 1;
  const week_count = Math.ceil((dd - d1 + 1) / 7);
  let allWeek = {},
    i = 0;
  for (; i < week_count; i++) {
    const sunday = fnToDub((d1 + i * 7) + 6);
    const start = year + '' + fnToDub(month) + '' + fnToDub(d1 + i * 7);
    const end = sunday <= dd ? year + '' + fnToDub(month) + '' + sunday : d.getFullYear() + '' + (d.getMonth() + 1) + '' + fnToDub(d.getDate());
    if (sunday > dd) d.setFullYear(year, fnToDub(month) - 1, sunday);
    allWeek[(i + 1)] = {
      start: start,
      end: end
    }
  }
  function g() {
    const num1 = new Date(year, month - 1, 0).getDate(),
      day = +allWeek[1].start.slice(-2) - 1;
    let ary = [],
      i = 1,
      j = 0;
    for (; i <= (7 - day); i++) {
      ary.unshift(num1 - i + 1);
    }
    for (; j < day; j++) {
      ary.push(j + 1)
    }
    return day > 0 ? ary : false;
  }
  if (g()) allWeek[0] = g();
  let array = [],
    dayType1 = +allWeek[1].start.slice(-2) === 1;
  for (let k in allWeek) {
    array[dayType1 ? k : +k + 1] = allWeek[k].length ? allWeek[k] : fnGenerateDate(allWeek[k].start.slice(-2), allWeek[k].end.slice(-2));
  }
  return array.filter(item => item)
}

/**
 * 创建人： 徐盼强
 * @param fn 需要防抖的函数
 * @param wait 防抖的时间
 */
const debounce = (fn, wait) => {
  var timeout = null;
  return function (e) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, arguments);
    }, wait)
  }
}

export {
  jurisdiction,
  phoneCodeValid,
  myTrim,
  isLogout,
  getTimeStr,
  getTimeData,
  shallowCopy,
  FormPhoneCode,
  FormChinese,
  FormNumber,
  FormChineseNumberEng,
  FormNumberEng,
  FormPassword,
  ifPermissions,
  getWeek,
  debounce,
  getMon,
  getCurrentDate
}

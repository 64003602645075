import { observable } from "mobx"

class Title {

  id = Math.random()

  @observable data = [{id: '123', name: '8888'}]

}

export default Title;
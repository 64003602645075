import React, { Component } from "react";
import { Form, Input, Modal } from 'antd'
import { Notify } from 'zent';
import { withRouter } from "react-router-dom"
import { FormPassword } from "../../utils/utils"
import { LoginOrRegister } from '../../models/login/login';
let loginOrRegister = new LoginOrRegister()

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}
@withRouter
class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitLoading: false
    }
    this.formRef = React.createRef()
  }

  // 提交修改密码
  handleSubmit = () => {
    this.formRef.current.submit()
  }

  async onFinish(values) {
    this.setState({
      submitLoading: true
    })
    let res = await loginOrRegister.changePassword(values)
    this.setState({
      submitLoading: false
    })
    if (res.success) {
      Notify.success('修改密码成功，请重新登陆')
      this.props.history.replace('/')
    }
  }


  render() {
    const { submitLoading } = this.state;
    return (
      <Modal
        title="修改密码"
        visible={this.props.changePasswordModal}
        onOk={this.handleSubmit}
        onCancel={this.props.closeChangePassword}
        okButtonProps={{ loading: submitLoading }}
        destroyOnClose
        cancelText='取消'
        okText='确认'
        width={480}
      >
        <Form
          className='form-box'
          ref={this.formRef}
          onFinish={this.onFinish.bind(this)}
        > <Form.Item
          {...formItemLayout}
          name="currentPassword"
          label="旧密码"
          rules={[
            {
              required: true,
              message: '请输入旧密码',
            },
          ]}
        >
            <Input.Password visibilityToggle={false} placeholder="请输入旧密码" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="newPassword"
            label="新密码"
            rules={[
              {
                required: true,
                message: '请输入新密码',
              },
              {
                validator: (rule, value, c) => FormPassword(rule, value) ? Promise.resolve() : Promise.reject("请输入正确的密码")
              },
            ]}
          >
            <Input.Password visibilityToggle={false} placeholder="密码为8-20位小写、大写、数字的组合" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            dependencies={['newPassword']}
            name="confirmPassword"
            label="确认新密码"
            rules={[
              {
                required: true,
                message: '请输入确认新密码',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('两次输入密码不一致');
                },
              }),
            ]}
          >
            <Input.Password visibilityToggle={false} placeholder="请确认新密码" />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default ChangePassword
import { observable, action } from 'mobx';
import routeLink from '../../pages/router/menuRouter';
import routeDetail from '../../pages/router/routeDetail';

class Router {

  @observable.ref routerArr = routeLink;

  @observable.ref routerDetail = routeDetail;

  @action replaceArr(arr) {
    this.routerArr = arr
  }

}

export default Router
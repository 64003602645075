import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import store from './store/index';
import App from './app';
import 'zent/css/index.css';
import './index.less';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import {ConfigProvider} from 'antd';
let root = document.getElementById('root')

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider {...store}>
            <App></App>
        </Provider>
    </ConfigProvider>
  , root)

serviceWorker.unregister()

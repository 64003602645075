import { observable, action} from 'mobx';

class Exam {

  @observable.ref questionDia = false;

  @observable.ref questionType = false;

  @observable.ref videoDia = false;

  @observable.ref selectedId = [];

  @observable.ref selectedIdVideo = [];

  @observable.ref selectQuestionType = [];

  @observable.ref list = [];

  @observable.ref listVideo = [];

  @observable.ref listQuestionType = [];

  @observable.ref selectlist = [];

  @observable.ref selectlistVideo = [];

  @observable.ref selectlistQuestionType = [];

  @observable.ref score = 0;

  @action resetDataLine(data) {
    this.dataLine = data || {}
  }

}

export default Exam
import AsyncComponent from './asyncComponent';

const detailRouter = [
  {
    path: '/region/manage/amount/:id',  //查看奶站
    permissions: "Pages.Dairys.Managements.Find",
    component: props => AsyncComponent(props, () => import('../region/manage/amount/amount')),
  },
  {
    path: '/count/health/record/:id', //健康统计 - 填报记录
    permissions: "Pages.Report.HealthRepor.Find",
    component: props => AsyncComponent(props, () => import('../count/health/record/record')),
  },
  {
    path: '/count/health/info/:id', //健康统计 - 个人信息
    permissions: "Pages.Report.HealthRepor.Find",
    component: props => AsyncComponent(props, () => import('../count/health/info/info')),
  },
  {
    path: '/count/miss/record/:id', //数据统计 - 填报记录
    permissions: "Pages.Report.DataReport.Find",
    component: props => AsyncComponent(props, () => import('../count/health/record/record')),
  },
  {
    path: '/count/miss/info/:id', //数据统计 - 个人信息
    permissions: "Pages.Report.DataReport.Find",
    component: props => AsyncComponent(props, () => import('../count/health/info/info')),
  },
  {
    path: '/staff/general/clockInDetail/:state/:id',  //编辑 查看 新建 打卡设置
    permissions: "Pages.Employees.Managements.Find",
    component: props => AsyncComponent(props, () => import('../staff/general/clockInDetail')),
  },
  {
    path: '/staff/general/detail/:id',  //查看员工
    permissions: "Pages.Employees.Managements.Find",
    component: props => AsyncComponent(props, () => import('../staff/general/detail')),
  },
  {
    path: '/staff/epidemic/detail/:id',  //查看员工防疫信息
    permissions: "Pages.Employees.Managements.NAT.Find",
    component: props => AsyncComponent(props, () => import('../staff/epidemic/detail')),
  },
  {
    path: '/staff/epidemic/audit/:id',  //审核员工防疫信息
    permissions: "Pages.Employees.Managements.NAT.Check",
    component: props => AsyncComponent(props, () => import('../staff/epidemic/audit')),
  },
  {
    path: '/staff/general/edit/:id',  //普通员工 - 添加/编辑员工
    permissions: "Pages.Employees.Managements.Create",
    component: props => AsyncComponent(props, () => import('../staff/general/edit')),
  },
  {
    path: '/staff/general/audit/:id', //审核员工
    permissions: "Pages.Employees.Managements.Check",
    component: props => AsyncComponent(props, () => import('../staff/general/audit')),
  },
  {
    path: '/staff/manager/editstaff/:id',    // 员工管理 - 管理人员 - 添加/编辑员工
    permissions: ["Pages.Employees.Users.Create", "Pages.Employees.Users.Update"],
    component: props => AsyncComponent(props, () => import('../staff/manager/editstaff')),
    // path: '/staff/manager/edit/:id',    // 员工管理 - 管理人员 - 添加/编辑员工
    // permissions: ["Pages.Employees.Users.Create", "Pages.Employees.Users.Update"],
    // component: props => AsyncComponent(props, () => import('../staff/manager/edit')),
  },
  {
    path: '/staff/organization/detail/:id', //组织架构 - 查看  【使用organization 404！！！】
    permissions: "Pages.Employees.Organization.Find",
    component: props => AsyncComponent(props, () => import('../staff/organization/detail')),
  },
  {
    path: '/finance/set/activity/:id',  //奖金设置 - 新建/编辑
    permissions: ["Pages.Finances.BonusSets.Create", "Pages.Finances.BonusSets.Update"],
    component: props => AsyncComponent(props, () => import('../finance/set/activity/activity')),
  },
  {
    path: '/finance/set/check/:id', //奖金设置 - 查看
    permissions: "Pages.Finances.BonusSets.Find",
    component: props => AsyncComponent(props, () => import('../finance/set/index/check')),
  },
  {
    path: '/finance/detail/item/:id', //奖金明细
    permissions: "Pages.Finances.BonusDetails.ViewDetail",
    component: props => AsyncComponent(props, () => import('../finance/detail/index/detail')),
  },
  {
    path: '/finance/send/item/:id', //发放明细 - 发放方案 - 编辑
    permissions: ['Pages.Finances.GrantDetails.GrantProjects.Update', 'Pages.Finances.GrantDetails.GrantProjects.Create'],
    component: props => AsyncComponent(props, () => import('../finance/send/newForm')),
  },
  {
    path: '/finance/send/check/:id', //发放明细 - 发放方案 - 查看
    permissions: 'Pages.Finances.GrantDetails.GrantProjects.Find',
    component: props => AsyncComponent(props, () => import('../finance/send/check')),
  },
  {
    path: '/finance/send/grant/:id',  //发放明细 - 发放报表 - 审核
    permissions: ['Pages.Finances.GrantDetails.GrantReports.Check'],
    component: props => AsyncComponent(props, () => import('../finance/send/grant')),
  },
  {
    path: '/finance/send/settlement/:id',  //发放明细 - 发放报表 - 查看详情
    permissions: ['Pages.Finances.GrantDetails.GrantReports.ViewDetail', 'Pages.Finances.GrantDetails.GrantReports.Check'],
    component: props => AsyncComponent(props, () => import('../finance/send/settlement')),
  },
  {
    path: '/finance/customRewards/create',
    component: props => AsyncComponent(props, () => import('../finance/customRewards/create')),
  },
  {
    path: '/set/msgTake/detail/:id',
    permissions: 'Pages.Settings.MessageManagement.SubscribeMessages.Find',
    component: props => AsyncComponent(props, () => import('../set/msgTake/detail')),
  },
  {
    path: '/exam/video/item/:id', //考试 - 视频库 - 创建/编辑
    permissions: ["Pages.Exams.VideoBanks.Create", "Pages.Exams.VideoBanks.Update"],
    component: props => AsyncComponent(props, () => import('../exam/video/new')),
  },
  // {
  // 	path: '/exam/test/item/:id',
  //   component: props => AsyncComponent(props, () => import('../exam/video/test')),
  // },
  {
    path: '/exam/question/item/:id', //考试 - 试题库 - 创建/编辑
    permissions: ["Pages.Exams.Question.Banks.Create", "Pages.Exams.Question.Banks.Update"],
    component: props => AsyncComponent(props, () => import('../exam/question/new')),
  },
  {
    path: '/exam/manage/item/:id',  //考试 - 考试管理 - 创建
    permissions: "Pages.Exams.Managements.Create",
    component: props => AsyncComponent(props, () => import('../exam/manage/new')),
  },
  {
    path: '/exam/manage/edit/:id',    //考试 - 考试管理 - 编辑试卷
    permissions: "Pages.Exams.Managements.Update",
    component: props => AsyncComponent(props, () => import('../exam/manage/editExam')),
  },
  {
    path: '/exam/manage/editQuestion/:id',   //考试 - 考试管理 - 编辑试题
    permissions: "Pages.Exams.Managements.Update",
    component: props => AsyncComponent(props, () => import('../exam/manage/editQuestion')),
  },
  {
    path: '/exam/manage/checkExam/:id',   //考试 - 考试管理 - 查看试卷
    permissions: "Pages.Exams.Managements.Find",
    component: props => AsyncComponent(props, () => import('../exam/manage/checkExam')),
  },
  {
    path: '/exam/manage/checkQuestion/:id', //考试 - 考试管理 - 查看试题
    permissions: "Pages.Exams.Managements.Find",
    component: props => AsyncComponent(props, () => import('../exam/manage/checkQuestion')),
  },
  {
    path: '/exam/manage/examDetail/:id',  //未找到
    permissions: "",
    component: props => AsyncComponent(props, () => import('../exam/manage/examDetail')),
  },
  {
    path: '/exam/manage/examList/:id/:type',//考试 - 考试管理 - 查看详情
    permissions: "Pages.Exams.Managements.Find",
    component: props => AsyncComponent(props, () => import('../exam/manage/examList')),
  },
  {
    path: '/exam/manage/answerDetail/:id',//考试 - 考试管理 - 查看详情  - 答题详情
    permissions: "Pages.Exams.Managements.Find",
    component: props => AsyncComponent(props, () => import('../exam/manage/answerDetail')),
  },
  {
    path: '/set/position/edit/:id',  // 设置 - 职位管理 - 添加/编辑职位
    permissions: ["Pages.Settings.Permissions.Create", "Pages.Settings.Permissions.Update"],
    component: props => AsyncComponent(props, () => import('../set/position/edit')),
  },
  {
    path: '/finance/customRewards/create',  //财务 - 自定义奖励 - 新建活动
    permissions: "Pages.Finances.CustomRewards.Create",
    component: props => AsyncComponent(props, () => import('../finance/customRewards/create')),
  },
  {
    path: '/finance/customRewards/detail/:id',  //财务 - 自定义奖励 - 查看详情
    permissions: "Pages.Finances.CustomRewards.Find",
    component: props => AsyncComponent(props, () => import('../finance/customRewards/detail')),
  },

  {
    path: '/customer/lead/lead',  //客户 - 批量导入 - 批量导入
    permissions: "Pages.CustomerPage.BulkImport.Import",
    component: props => AsyncComponent(props, () => import('../customer/lead/lead')),
  },

  {
    path: '/customer/manage/index/customerdetail/:id',  //客户 - 客户管理 - 查看
    permissions: "Pages.CustomerPage.CustomerPageManagements.Find",
    component: props => AsyncComponent(props, () => import('../customer/manage/index/customerdetail')),
  },
  {
    path: '/customer/lead/leaddetail:id',  //客户 - 批量导入 - 查看
    permissions: "Pages.CustomerPage.BulkImport.Find",
    component: props => AsyncComponent(props, () => import('../customer/lead/leaddetail')),
  },
  {
    path: '/customer/lead/leadfaildetail:id',  //客户 - 批量导入 - 失败
    permissions: "Pages.CustomerPage.BulkImport.Find",
    component: props => AsyncComponent(props, () => import('../customer/lead/leadfaildetail')),
  },
  {
    path: '/order/query/detail/:id',  //订单 - 查看详情
    permissions: "Pages.Orders.Search.Find",
    component: props => AsyncComponent(props, () => import('../order/query/detail')),
  },
  {
    path: '/order/docking/detail/:id',  //订单 - 查看详情
    permissions: "Pages.Orders.Search.Find",
    component: props => AsyncComponent(props, () => import('../order/docking/detail')),
  },
  {
    path: '/order/docking/unsubscribeDetail/:id',  //订单 - 查看详情
    permissions: "Pages.Orders.Search.Find",
    component: props => AsyncComponent(props, () => import('../order/docking/unsubscribeDetail')),
  },
  {
    path: '/order/docking/detailAssigned/:id',  // 和三元订单 - 新订单 查看详情
    permissions: "Pages.Orders.Search.Find",
    component: props => AsyncComponent(props, () => import('../order/docking/detailAssigned')),
  },
  // ------------- li add start ------------------
  {
    path: '/set/duty/edit/:id',  // 设置 - 职位管理 - 添加/编辑职位
    permissions: ["Pages.Settings.Titles.Create", "Pages.Settings.Titles.Update"],
    component: props => AsyncComponent(props, () => import('../set/duty/edit')),
  },
  {
    path: '/set/dataRights/edit/:id',  // 设置 - 数据权限 - 添加/编辑 数据权限
    permissions: ["Pages.Settings.Datas.Create", "Pages.Settings.Datas.Update"],
    component: props => AsyncComponent(props, () => import('../set/dataRights/edit')),
  },
  // ------------- li add end ------------------

  // ------------- 考题开始 ------------------
  {
    path: '/exam/lead/lead',  // 考试-试题导入-考题批量导入
    permissions: ["Pages.Exams.QuestionImports.Find"],
    component: props => AsyncComponent(props, () => import('../exam/lead/lead')),
  },
  {
    path: '/exam/lead/leaddetail:id',  // 考试-试题导入-考题批量成功
    permissions: ["Pages.Exams.QuestionImports.Find"],
    component: props => AsyncComponent(props, () => import('../exam/lead/leaddetail')),
  },
  {
    path: '/exam/lead/leadfaildetail:id',  // 考试-试题导入-考题批量失败
    permissions: ["Pages.Exams.QuestionImports.Find"],
    component: props => AsyncComponent(props, () => import('../exam/lead/leadfaildetail')),
  },
  // ------------- 考题结束 ------------------
  // ------------- 消息通知开始 ------------------
  {
    path: '/set/msgTake/pushDetails/:state/:id',  // 设置-消息通知
    permissions: ["Pages.Settings.MessageManagement.MessageNotice.Find"],
    component: props => AsyncComponent(props, () => import('../set/msgTake/pushDetails')),
  },
  // ------------- 消息通知结束 ------------------

  {
    path: '/management/receive/new/:id',  //资料 - 资料管理 - 发布资料
    permissions: "Pages.Means.Managements.Find",
    component: props => AsyncComponent(props, () => import('../management/receive/new')),
  },
  {
    path: '/management/receive/statistics/:id',  // 统计
    permissions: "Pages.Means.Managements.Find",
    component: props => AsyncComponent(props, () => import('../management/receive/statistics')),
  },

  // 推广
  {
    path: '/promotion/contentPromotion/edit/:id',  //推广 - 发布推广内容
    permissions: "Pages.Popularize.PopularizeContent.Create",
    component: props => AsyncComponent(props, () => import('../promotion/contentPromotion/edit')),
  },
  {
    path: '/promotion/contentReview/viewProject/:id', // 内容审核
    permissions: "Pages.Popularize.Managements",
    component: props => AsyncComponent(props, () => import('../promotion/contentReview/viewProject')),
  },
  {
    path: '/promotion/dataStatistics/viewPage/:id/:startTime/:endTime', // 数据统计
    permissions: "Pages.Popularize.Data",
    component: props => AsyncComponent(props, () => import('../promotion/dataStatistics/viewPage')),
  },
]

export default detailRouter;

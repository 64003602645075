import Todo from "./storeInfo/todo";
import Title from "./storeInfo/title";
import Router from "./router/router";
import MilkStation from "./milkStation/milkStation";
import Staff from "./staff/staff";
import Finance from "./finance/finance";
import Exam from "./exam/exam";

let todo = new Todo();
let title = new Title();
let router = new Router();
let staff = new Staff();
let milkStation = new MilkStation();
let finance = new Finance();
let exam = new Exam();

const store = {
  todo,
  title,
  router,
  staff,
  finance,
  milkStation,
  exam,
}

export default store;

import axios from 'axios';
import { Notify } from 'zent';
import { jurisdiction } from '../utils/utils';

// 请求超时时间
axios.defaults.timeout = 60000;

// 跨域请求头中允许携带资源凭证（如cookie 信息）
// axios.defaults.withCredentials = true

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const token = jurisdiction();
    token && (config.headers.Authorization = 'Bearer ' + token);
    return config;
  },
  error => {
    return Promise.error(error);
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          Notify.error('未登录')
          break;
        case 403:
          Notify.error('登录过期，请重新登录')
          break;
        case 404:
          Notify.error('网络请求不存在')
          break;
        case 415:
          Notify.error('参数错误')
          break;
        case 500:
          let msg = '对不起,在处理您的请求期间,产生了一个服务器内部错误!'
          Notify.error(error.response.data.error.message === msg ? '服务繁忙，请稍候再试': error.response.data.error.message)
          break;
        default:
          Notify.error('未知错误')
          break;
      }
      return Promise.resolve(error.response);
    }
  }
)

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function del(url, params) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params: params
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

// 以下待测试
export async function uploadImg(url, imgs) {
  return new Promise((resolve, reject) => {
    let formData = new FormData()
    formData.append('files', imgs[0])
    fetch(url, {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
      .then((resData) => {
        resolve(resData)
      })
      .catch((err) => {
        resolve(err)
      })
  })
}

export async function uploadFile(url, file) {
  return new Promise((resolve, reject) => {
    let formData = new FormData()
    formData.append('files', file[0])
    fetch(url, {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
      .then((resData) => {
        resolve(resData)
      })
      .catch((err) => {
        resolve(err)
      })
  })
}
